import {MRT_Row} from 'material-react-table';
import React from 'react';
import {ROUTES} from '@/config/routesConstants';
import type CreatorGroupMembership from '@/types/creatorGroupMembership';
import UsernameCell from '@/views/creators/components/creators-table/UsernameCell';
import Link from '@/components/link';
import useBrandId from '@/hooks/use-brand-id';
import useListCreatorsStore, {
  listCreatorsActions,
} from '@/stores/list-creators/listCreatorsStore';

function CreatorNameCell({row}: {row: MRT_Row<CreatorGroupMembership>}) {
  const {id} = row.original;
  const selectedCreators = useListCreatorsStore(
    (state) => state.selectedCreators
  );

  const imageUrl = row.original.profile?.imageUrl ?? '';
  const displayName =
    row.original.profile?.displayName ??
    row.original.email ??
    'Deactivated creator';

  const brandId = useBrandId();

  return (
    <Link
      to={`${ROUTES.CREATOR_PROFILE}/${row.original.profile?.id}?brandId=${brandId}`}
      target="_blank"
    >
      <UsernameCell
        showProfilePopup
        name={displayName}
        picture={imageUrl}
        isSelected={selectedCreators.has(id)}
        onCheckboxChange={() => {
          listCreatorsActions.toggleCreator(id, row.original);
        }}
        profile={row.original.profile}
        email={row.original?.email}
      />
    </Link>
  );
}

export default CreatorNameCell;
