import React, {useEffect} from 'react';
import {v4 as uuid} from 'uuid';
import AnalyticsService, {
  eventNames,
} from '@/services/analytics/AnalyticsService';
import {SOURCE_NAMES} from '@/lib/analytics/analyticsConstants';
import {buttonNames, screenNames} from '@/lib/delta/deltaConstants.js';
import calculateAge from '@/utils/calculateAge';
import type CreatorGroupMembership from '@/types/creatorGroupMembership';
import CreatorAction from '@/views/creators/components/creator-action/CreatorAction';
import CreatorsActionsBar from '@/views/creators/components/creators-actions-bar/CreatorsActionsBar';
import FloatingPopperBar from '@/components/floating-popper-bar';
import useListCreatorsStore, {
  listCreatorsActions,
} from '@/stores/list-creators/listCreatorsStore';
import useActionItems from '../hooks/useActionItems';
import styles from './ListCreatorsActionBar.module.scss';

function ListCreatorsActionBar({children}: {children: React.ReactNode}) {
  const POPPER_HORIZONTAL_PADDING = 16;
  const POPPER_MAX_WIDTH = 1100;
  const POPPER_PLACEMENT_OFFSET = 24;

  const activeCreatorAction = useListCreatorsStore(
    (state) => state.activeCreatorAction
  );

  const selectedCreators = useListCreatorsStore(
    (state) => state.selectedCreators
  );

  useEffect(() => {
    return () => {
      listCreatorsActions.deselectAllCreators();
    };
  }, []);

  const hasProfile = (membership: CreatorGroupMembership) =>
    membership.profile !== undefined;

  const selectedCreatorsProps = [...selectedCreators.values()]
    .filter(hasProfile)
    .map(({profile}) => ({
      id: profile.id,
      name: profile.displayName,
      avatar: profile.imageUrl,
      age: calculateAge(profile.birthDate),
      location: profile.location,
    }));

  const avatarUrls = React.useMemo(() => {
    return Array.from(selectedCreators.values())
      ?.filter(hasProfile)
      .map((creator: CreatorGroupMembership) => creator.profile.imageUrl);
  }, [selectedCreators]);

  const actionItems = useActionItems();

  const handleActionSubmit = (
    reason: 'success' | 'cancelled',
    actionName: string | null,
    creatorIds: string[]
  ) => {
    const creatorIdsObject = creatorIds.reduce(
      (acc: Record<string, string>, creatorId) => {
        acc[creatorId] = creatorId;
        return acc;
      },
      {}
    );
    if (reason === 'success') {
      const eventPayload = {
        screen_presentation_id: uuid(),
        action_name: actionName,
        creator_ids: creatorIdsObject,
        flow_name: actionName?.replace('-', ' ').toLowerCase(),
        campaign_id: '',
        reason: 'success',
        source: SOURCE_NAMES.MY_CREATORS,
        error: null,
      };
      AnalyticsService.dispatchEvent(
        eventNames.MY_CREATORS_ACTION_FLOW_ENDED,
        eventPayload
      );
    } else if (reason === 'cancelled') {
      const eventPayload = {
        screen_presentation_id: uuid(),
        action_name: actionName,
        creator_ids: creatorIdsObject,
        flow_name: actionName?.replace('-', ' ').toLowerCase(),
        campaign_id: '',
        reason: 'cancelled',
        source: SOURCE_NAMES.MY_CREATORS,
        error: null,
      };
      AnalyticsService.dispatchEvent(
        eventNames.MY_CREATORS_ACTION_FLOW_ENDED,
        eventPayload
      );
    }

    listCreatorsActions.setActiveCreatorAction(null);
  };

  return (
    <FloatingPopperBar
      isOpen={selectedCreators.size > 0}
      placement="bottom"
      className={styles.container}
      widthCalculation={(width) =>
        width
          ? Math.min(width - POPPER_HORIZONTAL_PADDING * 2, POPPER_MAX_WIDTH)
          : 'auto'
      }
      placementMargin={POPPER_PLACEMENT_OFFSET}
      renderContent={() => (
        <CreatorsActionsBar
          avatarUrls={avatarUrls}
          actionItems={actionItems}
          closeButtonProps={{
            appearance: 'neutral',
            size: 'medium',
            mode: 'plain',
          }}
          closeButtonText="Clear selection"
          closeButtonOnClick={() => {
            listCreatorsActions.deselectAllCreators();

            AnalyticsService.dispatchEvent(eventNames.BUTTON_PRESSED, {
              button_name: buttonNames.MY_CREATORS.CLEAR_SELECTION,
              creator_id: selectedCreatorsProps.map((c) => c.id).join(','),
              screen_name: screenNames.MY_CREATORS.MY_CREATORS,
              flow_id: '',
              flow_name: '',
              triggered_flow_id: '',
              triggered_flow_name: '',
              screen_presentation_id: uuid(),
              tab: '',
              campaign_id: '',
            });
          }}
        />
      )}
    >
      <CreatorAction
        // initState={actionInitState}
        activeCreatorAction={activeCreatorAction}
        creators={selectedCreatorsProps}
        close={(reason) => {
          listCreatorsActions.setActiveCreatorAction(null);

          handleActionSubmit(
            reason,
            activeCreatorAction,
            selectedCreatorsProps.map((c) => c.id)
          );
        }}
      />
      {children}
    </FloatingPopperBar>
  );
}

export default ListCreatorsActionBar;
