import React from 'react';
import {TiktokAccountWithAudiences} from '@/types/TiktokAccount';
import {Identity} from '@/types/identity';
import IdentitySocialHealth from '@/components/creator-profile/components/identity-social-health';
import SocialNetworkSelector from '@/components/creator-profile/components/social-network-selector';
import TiktokSocialHealth from '@/components/creator-profile/components/tiktok-social-health';
import {TIKTOK_IDENTITY_IDS} from '@/components/creator-profile/constants';
import useSocialNetworksSelector from '@/components/creator-profile/hooks/use-social-networks-selector';
import useIdentityPostsQuery from '@/hooks/queries/use-identity-posts-query';
import useTiktokAccountQuery from '@/hooks/queries/use-tiktok-account-query';
import {CreatorProfileTabContentProps} from '../../CreatorProfileTabContentProps';
import TabWrapper from '../tab-wrapper';
import styles from './AnalyticsAndAudienceTab.module.scss';

const TIKTOK_ACCOUNT_INCLUDE = ['current_audience', 'audiences'];

function AnalyticsAndAudienceTab(props: CreatorProfileTabContentProps) {
  const {profile, creatorProfileAbilities, isSharedList, trackButtonPressed} =
    props;
  const {socialNetworks, activeIdentity, onSocialNetworkClick} =
    useSocialNetworksSelector(profile.identities);

  const handleSocialNetworkClick = (identity: Identity) => {
    onSocialNetworkClick(identity);
    trackButtonPressed(`Social Network: ${identity.provider}`);
  };

  const isTiktokIdentity = TIKTOK_IDENTITY_IDS.includes(
    activeIdentity?.provider || ''
  );

  const {tiktokAccount, isLoading: isLoadingTiktokAccount} =
    useTiktokAccountQuery({
      accountId: activeIdentity?.uid || '',
      enabled: isTiktokIdentity && !!activeIdentity?.uid,
      include: TIKTOK_ACCOUNT_INCLUDE,
    });

  const {data: posts, isLoading: isLoadingPosts} = useIdentityPostsQuery({
    identityId: activeIdentity?.id,
    enabled: !isTiktokIdentity,
    queryParams: {
      socialStream: 'feed',
    },
  });

  const {
    data: postsIncludingDeleted,
    isLoading: isLoadingPostsIncludingDeleted,
  } = useIdentityPostsQuery({
    identityId: activeIdentity?.id,
    enabled: !isTiktokIdentity,
    queryParams: {
      socialStream: 'feed',
      allowDeletedPosts: true,
    },
  });

  const renderActiveIdentitySocialHealth = () => {
    if (activeIdentity) {
      if (isTiktokIdentity) {
        return (
          <TiktokSocialHealth
            creatorProfileAbilities={creatorProfileAbilities}
            identity={activeIdentity}
            tiktokAccount={tiktokAccount as TiktokAccountWithAudiences}
            isLoadingTiktokAccount={isLoadingTiktokAccount}
          />
        );
      }
      return (
        <IdentitySocialHealth
          creatorProfileAbilities={creatorProfileAbilities}
          identity={activeIdentity}
          posts={posts}
          postsIncludingDeleted={postsIncludingDeleted}
          isLoadingPosts={isLoadingPosts}
          isLoadingPostsIncludingDeleted={isLoadingPostsIncludingDeleted}
        />
      );
    }
    return null;
  };

  return (
    <TabWrapper className={styles.analyticsAndAudienceTabTabContainer}>
      <div
        className={`${styles.socialNetworksBar} ${
          isSharedList ? styles.whiteBackground : ''
        }`}
      >
        <SocialNetworkSelector
          socialNetworks={socialNetworks}
          activeSocialNetworkProvider={activeIdentity?.provider || ''}
          onSocialNetworkClick={handleSocialNetworkClick}
        />
      </div>
      {renderActiveIdentitySocialHealth()}
    </TabWrapper>
  );
}

export default AnalyticsAndAudienceTab;
