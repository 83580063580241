import CreatorGroupMembership from '@/types/creatorGroupMembership';
import useBrandCreatorGroupsQuery from '@/hooks/queries/use-brand-creator-groups-query';
import useCreatorGroupsLabelsQuery from '@/hooks/queries/use-creator-groups-labels-query';
import useCreatorGroupsMembershipsQuery from '@/hooks/queries/use-creator-groups-memberships-query';
import useBrandId from '@/hooks/use-brand-id';
import useListCreatorsStore from '@/stores/list-creators/listCreatorsStore';

function useMembershipsAndLabels() {
  const brandId = useBrandId();
  const selectedLists = useListCreatorsStore((state) => state.selectedLists);
  const tableFilterValue = useListCreatorsStore(
    (state) => state.tableFilterValue
  );
  const sorting = useListCreatorsStore((state) => state.sorting);
  const {creatorGroups} = useBrandCreatorGroupsQuery({brandId});
  const creatorGroupId = creatorGroups[0]?.id ?? '';
  const membershipQueryKey = {
    creatorGroupId,
    labelIds: [...selectedLists],
    q: tableFilterValue,
    sorting,
  };

  const membershipsResponse =
    useCreatorGroupsMembershipsQuery(membershipQueryKey);

  const memberships =
    (membershipsResponse.data as CreatorGroupMembership[]) ?? [];

  const labelsResponse = useCreatorGroupsLabelsQuery({
    creatorGroupId,
    refetchOnWindowFocus: false,
  });

  return {
    memberships,
    labels: labelsResponse.labels,
    isLoading: membershipsResponse.isLoading || labelsResponse.isLoading,
    isFetching: membershipsResponse.isFetchingNextPage,
    membershipQueryKey,
    creatorGroupId,
    fetchNextPage: membershipsResponse.fetchNextPage,
  };
}

export default useMembershipsAndLabels;
