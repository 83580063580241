import React, {useMemo} from 'react';
import translate from '@/utils/translate';
import {TiktokAccountWithAudiences} from '@/types/TiktokAccount';
import {CreatorProfileAbilities} from '@/types/creatorProfile';
import {Identity} from '@/types/identity';
import PoppaysLoader from '@/components/PoppaysLoader';
import Conditional from '@/components/conditional';
import AudienceGraphics from '@/components/creator-profile/components/tiktok-social-health/components/audience-graphics';
import FollowersCountChart from '@/components/creator-profile/components/tiktok-social-health/components/followers-count-chart';
import FollowersCountVsEngagementRateChart from '@/components/creator-profile/components/tiktok-social-health/components/followers-count-vs-engagement-rate-chart';
import HealthScores from '@/components/creator-profile/components/tiktok-social-health/components/health-scores';
import ViewsChart from '@/components/creator-profile/components/tiktok-social-health/components/views-chart';
import ViewsPerFollowerChart from '@/components/creator-profile/components/tiktok-social-health/components/views-per-follower-chart';
import styles from './TiktokSocialHealth.module.scss';

export interface TiktokSocialHealthProps {
  testID?: string;
  creatorProfileAbilities: CreatorProfileAbilities;
  identity: Identity;
  tiktokAccount: TiktokAccountWithAudiences;
  isLoadingTiktokAccount: boolean;
}

function NeedsRelink() {
  return (
    <div className={styles.needsRelink}>
      <img src="/assets/svg/icon-bar-chart.svg" alt="Bar Chart SVG" />
      <h2>
        {translate(
          'components.social-network-identity-summary.profile-identity-summary.needs-relink-title'
        )}
      </h2>
      <span>
        {translate(
          'components.social-network-identity-summary.profile-identity-summary.needs-relink'
        )}
      </span>
    </div>
  );
}

function TiktokSocialHealth(props: TiktokSocialHealthProps) {
  const {
    testID = 'tiktok-social-health',
    creatorProfileAbilities,
    identity,
    tiktokAccount,
    isLoadingTiktokAccount,
  } = props;

  const showHealthScores = useMemo(() => {
    return creatorProfileAbilities.showSocialHealth;
  }, [creatorProfileAbilities.showSocialHealth]);

  const showAudienceGraphics = useMemo(() => {
    return creatorProfileAbilities.showAudience;
  }, [creatorProfileAbilities.showAudience]);

  if (!tiktokAccount || isLoadingTiktokAccount) {
    return (
      <div className={styles.loader}>
        <PoppaysLoader absolute />
      </div>
    );
  }

  if (tiktokAccount?.needsRelink) {
    return <NeedsRelink />;
  }

  return (
    <div data-testid={testID}>
      <Conditional condition={showHealthScores}>
        <HealthScores tiktokAccount={tiktokAccount} />
      </Conditional>
      <Conditional condition={showAudienceGraphics}>
        <AudienceGraphics identity={identity} tiktokAccount={tiktokAccount} />
      </Conditional>
      <Conditional condition={creatorProfileAbilities.showSocialHealth}>
        <div className={styles.socialHealthCharts}>
          <FollowersCountChart
            identity={identity}
            audiences={tiktokAccount.audiences}
          />
          <FollowersCountVsEngagementRateChart
            identity={identity}
            audiences={tiktokAccount.audiences}
          />
          <ViewsChart identity={identity} audiences={tiktokAccount.audiences} />
          <ViewsPerFollowerChart
            identity={identity}
            audiences={tiktokAccount.audiences}
          />
        </div>
      </Conditional>
    </div>
  );
}

export default TiktokSocialHealth;
