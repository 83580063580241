import React from 'react';
import translate from '@/utils/translate';
import {TiktokAccount} from '@/types/TiktokAccount';
import styles from './HealthScores.module.scss';
import MetricCard from './MetricCard';

const TRANSLATION_PREFIX =
  'components.social-network-identity-summary.health-scores';

interface SocialScoresProps {
  tiktokAccount: TiktokAccount;
}

function HealthScores(props: SocialScoresProps) {
  const {tiktokAccount} = props;

  const {currentAudience, communityAverages} = tiktokAccount;

  return (
    <div className={styles.healthScoresContainer}>
      <MetricCard
        title={translate(`${TRANSLATION_PREFIX}.engagement-rate`)}
        value={currentAudience?.engagementRate || 0}
        communityAverage={communityAverages.engagementRate || 0}
      />
      <MetricCard
        title={translate(`${TRANSLATION_PREFIX}.views-to-followers`)}
        value={currentAudience?.viewsToFollowersCount || 0}
        communityAverage={communityAverages.viewsToFollowersRatio || 0}
      />
      <MetricCard
        title={translate(`${TRANSLATION_PREFIX}.audience-activity`)}
        value={tiktokAccount.audienceActivity}
        communityAverage={communityAverages.audienceActivity || 0}
      />
    </div>
  );
}

export default HealthScores;
