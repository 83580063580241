import React, {useMemo, useState} from 'react';
import {
  Button,
  designSystemToken,
  Headline,
  Label,
  Tooltip,
} from '@lightricks/react-design-system';
import calculateAge from '@/utils/calculateAge';
import getAnalyticsScreenName from '@/utils/getAnalyticsScreenName';
import queryClient from '@/utils/reactQueryClient';
import translate from '@/utils/translate';
import {ROUTES} from '@/config/routesConstants';
import {
  CreatorProfileAbilities,
  ProfileProps,
  MembershipLabel,
} from '@/types/creatorProfile';
import {Identity} from '@/types/identity';
import {MediaFile} from '@/types/mediaFile';
import CreatorAction from '@/views/creators/components/creator-action';
import {
  CREATOR_ACTION_KEYS,
  CreatorActionKey,
} from '@/views/creators/components/creator-action/CreatorAction';
import {CreatorProps} from '@/views/creators/components/creator-action/action/ActionProps';
import Conditional from '@/components/conditional';
import CollaborateButton from '@/components/creator-profile/components/collaborate-button';
import CreatorProfileRatingAndLastActiveAt from '@/components/creator-profile/components/creator-profile-rating-and-last-active-at';
import SavedInLists from '@/components/creator-profile/components/saved-in-lists';
import Link from '@/components/link';
import useTrackRecruitmentActionFlowEvent from '@/hooks/analytics/use-track-recruitment-action-flow-event';
import useBrandId from '@/hooks/use-brand-id';
import useIsMobile from '@/hooks/use-is-mobile';
import useNavigation from '@/hooks/use-navigation';
import useRaiseCreatorAddedToListSuccess from '@/hooks/use-raise-creators-added-to-list-success';
import CreatorProfileAvatar from '../creator-profile-avatar';
import CreatorProfileInfo from '../creator-profile-info';
import CreatorProfileSocialNetworks from '../creator-profile-social-networks';
import styles from './Header.module.scss';

const TRANSLATION_PREFIX = 'components.creator-profile.header';

interface HeaderProps {
  profile: ProfileProps;
  creatorProfileAbilities: CreatorProfileAbilities;
  trackButtonPressed: (buttonName: string, overrideParams?: object) => void;
  isModal?: boolean;
}

interface OverrideParams {
  [key: string]: string;
}

function CreatorNameWrap({
  url,
  children,
  trackButtonPressed,
}: {
  url: string | null;
  children: React.ReactNode;
  trackButtonPressed: (buttonName: string, overrideParams?: object) => void;
}) {
  return url ? (
    <Link
      to={url}
      className={styles.link}
      onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
        trackButtonPressed((event.target as HTMLAnchorElement).innerText, {
          flow_name: 'Open profile page',
        })
      }
    >
      {children}
    </Link>
  ) : (
    children
  );
}

function Header(props: HeaderProps) {
  const isMobile = useIsMobile();
  const brandId = useBrandId();
  const {navigate} = useNavigation();
  const {profile, creatorProfileAbilities, trackButtonPressed, isModal} = props;
  const [activeCreatorAction, setActiveCreatorAction] =
    useState<CreatorActionKey | null>(null);
  const raiseCreatorAddedToListSuccess = useRaiseCreatorAddedToListSuccess();
  const trackRecruitmentActionFlowEvent = useTrackRecruitmentActionFlowEvent();

  /* ====== Analytics functions ====== */
  const trackRecruitmentEventStarted = (actionName: string) => {
    const screenName = getAnalyticsScreenName(window.location.pathname);
    const source = `creator profile ${isModal ? 'modal' : 'page'}`;
    trackRecruitmentActionFlowEvent.started({
      flow_name: actionName,
      action_name: actionName,
      screen_name: screenName,
      recruitment_search_id: '',
      creator_id: profile.id,
      source,
    });
  };

  const trackRecruitmentEventEnded = (
    actionName: string,
    reason: string,
    overrideParams = {}
  ) => {
    const screenName = getAnalyticsScreenName(window.location.pathname);
    const source = `creator profile ${isModal ? 'modal' : 'page'}`;
    trackRecruitmentActionFlowEvent.ended({
      flow_name: actionName,
      action_id: actionName || '',
      screen_name: screenName,
      recruitment_search_id: '',
      creator_id: profile.id,
      reason,
      source,
      ...overrideParams,
    });
  };

  /* ====== Creator labels calculations ====== */
  const creatorProfileLabels = useMemo(() => {
    if (profile.currentBrandMembership) {
      return profile.currentBrandMembership.labels.map((label) => label.name);
    }
    return [];
  }, [profile.currentBrandMembership]);

  const currentBrandMembershipLabels = useMemo(() => {
    if (profile.currentBrandMembership) {
      return profile.currentBrandMembership.labels.map((label) => ({
        ...label,
        value: label.id,
        label: label.name,
        disabled: true,
      }));
    }
    return [];
  }, [profile.currentBrandMembership]);

  const profileForCreatorAction = useMemo(() => {
    return {
      ...profile,
      age: calculateAge(profile.birthDate),
      name: profile.displayName,
      avatar: profile.imageUrl,
    };
  }, [profile]);

  /* ====== Handlers ====== */
  const onCollaborateButtonClick = () => {
    trackButtonPressed('collaborate');
    if (profile.currentBrandCollaborationConversation?.id) {
      trackRecruitmentEventStarted(CREATOR_ACTION_KEYS.MESSAGE_CREATOR);
      trackRecruitmentEventEnded(
        CREATOR_ACTION_KEYS.MESSAGE_CREATOR,
        'collaboration resumed'
      );
      const redirectUrl = `/collaborations/${
        profile.currentBrandCollaborationConversation.id
      }${brandId ? `?brandId=${brandId}` : ''}`;
      navigate(redirectUrl);
      // window.open(redirectUrl, '_blank');
    } else {
      setActiveCreatorAction(CREATOR_ACTION_KEYS.MESSAGE_CREATOR);
    }
  };

  const onAddToListClicked = (event: React.MouseEvent<HTMLButtonElement>) => {
    trackButtonPressed((event.target as HTMLButtonElement).innerText);
    setActiveCreatorAction(CREATOR_ACTION_KEYS.ADD_TO_MY_CREATORS);
  };

  const onInviteToCampaignClicked = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    trackButtonPressed((event.target as HTMLButtonElement).innerText);
    setActiveCreatorAction(CREATOR_ACTION_KEYS.ADD_TO_CAMPAIGN);
  };

  const onSocialNetworkClick = (identity: Identity) => {
    trackButtonPressed(`ProfileHeader => Open ${identity.provider} profile`, {
      flow_id: identity.id,
      flow_name: identity.profilePageUrl,
    });
  };

  const onCloseCreatorAction = (
    reason: 'success' | 'cancelled',
    creators?: CreatorProps[],
    actionName?: string,
    result?: unknown
  ) => {
    const overrideParams: OverrideParams = {};
    if (reason === 'success') {
      if (actionName === CREATOR_ACTION_KEYS.ADD_TO_MY_CREATORS) {
        raiseCreatorAddedToListSuccess(
          (result as MembershipLabel[]).filter(
            (label: MembershipLabel & {disabled?: boolean}) => !label.disabled
          )
        );
      }
      if (
        [
          CREATOR_ACTION_KEYS.MESSAGE_CREATOR,
          CREATOR_ACTION_KEYS.ADD_TO_MY_CREATORS,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
        ].includes(actionName)
      ) {
        // invalidate the query and refetch the creator profile =>
        // some actions such as sending a message to a creator would change the collaborate button behavior
        queryClient.invalidateQueries([
          'creatorProfile',
          creatorProfileAbilities.displayPublicDataOnly,
          profile.id,
          brandId,
        ]);
      }
      if (actionName === CREATOR_ACTION_KEYS.ADD_TO_CAMPAIGN) {
        overrideParams.campaign_id = result as string;
      }
    }
    trackRecruitmentEventEnded(actionName || '', reason, overrideParams);
    setActiveCreatorAction(null);
  };

  const handlePresentedOrDismissed = (
    presentedOrDismissed: 'presented' | 'dismissed',
    actionName: string,
    creators?: CreatorProps[]
  ) => {
    if (presentedOrDismissed === 'presented') {
      trackRecruitmentEventStarted(actionName);
    } else if (presentedOrDismissed === 'dismissed') {
      // do nothing.... for now
    }
  };

  /* ====== Render ====== */
  return (
    <>
      <CreatorAction
        creators={[profileForCreatorAction]}
        activeCreatorAction={activeCreatorAction}
        close={onCloseCreatorAction}
        onPresentedOrDismissed={handlePresentedOrDismissed}
        initState={{
          [CREATOR_ACTION_KEYS.ADD_TO_MY_CREATORS]:
            currentBrandMembershipLabels,
        }}
      />
      <div className={styles.headerContainer}>
        <div className={styles.avatarContainer}>
          <div className={styles.fullWidth} />
          <div className={styles.avatar}>
            <CreatorProfileAvatar
              profileImageUrl={profile.imageUrl}
              profileDisplayName={profile.displayName}
              size="3xl"
              onClick={(mediaFile: MediaFile) => {
                trackButtonPressed(`Open creator avatar`, {
                  flow_id: mediaFile.kind,
                  flow_name: mediaFile.url,
                });
              }}
            />
          </div>
          <div className={styles.fullWidth}>
            <Conditional
              condition={
                !creatorProfileAbilities.displayPublicDataOnly &&
                isMobile &&
                creatorProfileAbilities.showCollaborationButton
              }
            >
              <CollaborateButton onClick={onCollaborateButtonClick} />
            </Conditional>
          </div>
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.row}>
            <div className={`${styles.column} ${styles.smallGap}`}>
              <CreatorNameWrap
                url={
                  isModal
                    ? `${ROUTES.CREATOR_PROFILE}/${profile.id}?brandId=${brandId}`
                    : null
                }
                trackButtonPressed={trackButtonPressed}
              >
                <Headline
                  size="lg"
                  color={designSystemToken('semantic.fg.primary')}
                >
                  {profile.displayName}
                </Headline>
              </CreatorNameWrap>
              <CreatorProfileInfo profile={profile} />
              <CreatorProfileRatingAndLastActiveAt
                profile={profile}
                showProfileRating={
                  !creatorProfileAbilities.displayPublicDataOnly
                }
                showCompletedGigsCount={
                  creatorProfileAbilities.displayPublicDataOnly ||
                  !!profile.completedPaidGigsCount
                }
                showLastActiveAt={
                  !!(
                    !creatorProfileAbilities.displayPublicDataOnly &&
                    creatorProfileAbilities.showLastActiveAt &&
                    profile.lastActiveAt
                  )
                }
              />
              <Conditional
                condition={
                  creatorProfileAbilities.showMembershipLabels &&
                  creatorProfileLabels.length > 0
                }
              >
                <SavedInLists
                  labels={creatorProfileLabels}
                  onToggle={(isOpen: boolean) => {
                    trackButtonPressed(
                      isOpen ? 'Saved in lists: OPEN' : 'Saved in lists: CLOSE'
                    );
                  }}
                />
              </Conditional>
            </div>
            <div
              className={`${styles.column} ${styles.smallColumn} ${styles.bottomColumn}`}
            >
              <Conditional
                condition={
                  !creatorProfileAbilities.displayPublicDataOnly &&
                  !isMobile &&
                  creatorProfileAbilities.showCollaborationButton
                }
              >
                <Tooltip
                  arrow
                  type="light"
                  placement="top"
                  disableInteractive
                  title={translate(
                    `${TRANSLATION_PREFIX}.collaborate-button-tooltip`,
                    {creatorName: profile.displayName}
                  )}
                >
                  <div>
                    <CollaborateButton onClick={onCollaborateButtonClick} />
                  </div>
                </Tooltip>
              </Conditional>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.column}>
              <Conditional
                condition={!creatorProfileAbilities.displayPublicDataOnly}
              >
                <CreatorProfileSocialNetworks
                  profile={profile}
                  onSocialNetworkClick={onSocialNetworkClick}
                />
              </Conditional>
            </div>
            <div className={styles.column}>
              <Conditional
                condition={!creatorProfileAbilities.displayPublicDataOnly}
              >
                <div className={styles.actionsContainer}>
                  <div className={styles.membershipAndInviteButtonsContainer}>
                    <Conditional
                      condition={
                        creatorProfileAbilities.showAddToMyCreatorsButton
                      }
                    >
                      <Button
                        className={styles.actionButton}
                        appearance="neutral"
                        mode="tinted"
                        size="medium"
                        onClick={onAddToListClicked}
                      >
                        {translate(`${TRANSLATION_PREFIX}.add-to-list-button`)}
                      </Button>
                    </Conditional>
                    <Conditional
                      condition={
                        creatorProfileAbilities.showInviteToCampaignButton
                      }
                    >
                      <Button
                        className={styles.actionButton}
                        appearance="neutral"
                        mode="filled"
                        size="medium"
                        onClick={onInviteToCampaignClicked}
                      >
                        {translate(
                          `${TRANSLATION_PREFIX}.invite-to-campaign-button`
                        )}
                      </Button>
                    </Conditional>
                  </div>
                </div>
              </Conditional>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
