import {SortingState} from '@tanstack/table-core';
import clsx from 'clsx';
import {MRT_ColumnDef} from 'material-react-table';
import React, {useState, useRef} from 'react';
import {v4 as uuid} from 'uuid';
import AnalyticsService, {
  eventNames,
} from '@/services/analytics/AnalyticsService';
import {SOURCE_NAMES} from '@/lib/analytics/analyticsConstants';
import translate from '@/utils/translate';
import type CreatorGroupMembership from '@/types/creatorGroupMembership';
import getCreatorIdsForAnalytics from '@/views/creators/utils/getCreatorIdsForAnalytics';
import Table from '@/components/table';
import useSubscriptionQuery from '@/hooks/queries/use-subscription-query';
import useBrandId from '@/hooks/use-brand-id';
import useListCreatorsStore, {
  listCreatorsActions,
} from '@/stores/list-creators/listCreatorsStore';
import styles from './ListCreatorsTable.module.scss';
import {
  CreatorNameCell,
  CreatorNameHeader,
  DateAddedCell,
  ListsCell,
  StatusCell,
  VetCreatorCell,
} from './components/table-cells';
import VetCreatorPopover from './components/table-cells/VetCreator/VetCreatorPopover';

const TRANSLATION_PREFIX = 'components.list-creators-table';

type ListCreatorsTableProps = {
  data: CreatorGroupMembership[];
  fetchNextPage: () => void;
  isLoading: boolean;
  isFetching: boolean;
  onVettingSuccess: () => void;
};

function ListCreatorsTable(props: ListCreatorsTableProps) {
  const {data, isLoading, isFetching, fetchNextPage, onVettingSuccess} = props;
  const sorting = useListCreatorsStore((state) => state.sorting);
  const selectedCreators = useListCreatorsStore(
    (state) => state.selectedCreators
  );
  const [activeVetPopoverId, setActiveVetPopoverId] = useState<string | null>(
    null
  );
  const [selectedMembership, setSelectedMembership] =
    useState<CreatorGroupMembership | null>(null);
  const vetButtonRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const brandId = useBrandId();
  const {subscription} = useSubscriptionQuery({brandId});
  const [flowId, setFlowId] = useState<string | null>(null);
  const [screenPresentationId, setScreenPresentationId] = useState<
    string | null
  >(null);

  const handleVetButtonClick = (
    membership: CreatorGroupMembership,
    event: React.MouseEvent<Element, MouseEvent>
  ) => {
    event.stopPropagation();
    setSelectedMembership(membership);
    setAnchorEl(event.currentTarget as HTMLElement);
    setActiveVetPopoverId(membership.id);

    const newFlowId = uuid();
    const newScreenPresentationId = uuid();
    setFlowId(newFlowId);
    setScreenPresentationId(newScreenPresentationId);

    AnalyticsService.dispatchEvent(eventNames.VETTING_CLICK, {
      flow_id: newFlowId,
      screen_presentation_id: newScreenPresentationId,
      creator_ids: getCreatorIdsForAnalytics([membership]),
      source: SOURCE_NAMES.MY_CREATORS,
    });
  };

  const handleCloseVetPopover = () => {
    setActiveVetPopoverId(null);
    setSelectedMembership(null);
  };

  const tableColumns: MRT_ColumnDef<CreatorGroupMembership>[] = [
    {
      header: translate(`${TRANSLATION_PREFIX}.header.name`),
      Cell: CreatorNameCell,
      accessorKey: 'creator_name',
      Header: <CreatorNameHeader memberships={data} isLoading={isLoading} />,
    },
    {
      header: translate(`${TRANSLATION_PREFIX}.header.date`),
      Cell: DateAddedCell,
      accessorKey: 'created_at',
      enableSorting: true,
      maxSize: 100,
    },
    {
      header: translate(`${TRANSLATION_PREFIX}.header.status`),
      Cell: StatusCell,
      accessorKey: 'status',
      maxSize: 100,
    },
    {
      header: translate(`${TRANSLATION_PREFIX}.header.lists`),
      Cell: ListsCell,
      accessorKey: 'label',
    },
  ];

  if (subscription?.isPlatform || subscription?.isSelfServe) {
    tableColumns.push({
      id: 'vetCreator',
      header: '',
      Cell: (cellProps) => {
        const {row} = cellProps;
        return (
          <VetCreatorCell
            row={row}
            onVetButtonClick={handleVetButtonClick}
            vetButtonRef={vetButtonRef}
            vetCreatorButtonClass={styles.vetCreatorButton}
            activeVetPopoverClass={styles.vetPopoverOpen}
            isVetPopoverActive={activeVetPopoverId === row.original.id}
          />
        );
      },
    });
  }

  const selectedCreatorsMap = Object.fromEntries(
    Array.from(selectedCreators, ([key]) => [key, true])
  );

  return (
    <>
      <Table
        classes={{
          container: clsx(styles.container, isFetching && styles.loading),
          footer: selectedCreators.size > 0 ? styles.highFooter : '',
        }}
        columns={tableColumns}
        data={data ?? []}
        isLoadingInitial={isLoading}
        showProgressBars={false} // TODO: use isRefetching
        visibleColumns={{creatorName: true}}
        rowSelection={selectedCreatorsMap}
        sorting={sorting}
        onSorting={listCreatorsActions.setSorting}
        isLoading={isFetching}
        onEndReached={() => {
          fetchNextPage();
        }}
        enableVirtualization
        enableSorting
      />
      {selectedMembership && (
        <VetCreatorPopover
          open={activeVetPopoverId !== null}
          onClose={handleCloseVetPopover}
          membership={selectedMembership}
          anchorEl={anchorEl}
          onVettingSuccess={onVettingSuccess}
          flowId={flowId ?? ''}
          screenPresentationId={screenPresentationId ?? ''}
        />
      )}
    </>
  );
}

export default ListCreatorsTable;
