import {ChartDataset} from 'chart.js';
import React, {useMemo} from 'react';
import getCountryFullName from '@/utils/getCountryFullName';
import getStateFullName from '@/utils/getStateFullName';
import translate from '@/utils/translate';
import LEGACY_COLORS from '@/config/legacyColors';
import {TiktokAccount} from '@/types/TiktokAccount';
import {Identity} from '@/types/identity';
import {Subscription} from '@/types/subscription';
import BarChart from '@/components/charts/bar-chart';
import DonutChart from '@/components/charts/donut-chart';
import LocationsList from '@/components/locations-list';
import styles from './AudienceGraphics.module.scss';

const TRANSLATION_PREFIX = 'components.creator-profile.tiktok-social-health';

const MALE_COLOR = LEGACY_COLORS.BLUE_DARK;
const FEMALE_COLOR = LEGACY_COLORS.PURPLE;

interface AudienceGraphicsProps {
  identity: Identity;
  tiktokAccount: TiktokAccount;
}

function AudienceGraphics(props: AudienceGraphicsProps) {
  const {identity, tiktokAccount} = props;

  const {currentAudience: audience} = tiktokAccount;
  const {maleGenderPercentage = 0, femaleGenderPercentage = 0} = audience || {};

  const ageRangeDatasets = useMemo(() => {
    const datasets: ChartDataset<'bar', number[]>[] = [];

    if (!audience || !audience.ages) {
      return datasets;
    }

    datasets.push({
      backgroundColor: LEGACY_COLORS.GREEN,
      data: audience.ages.map((ageGroup) => ageGroup.percentage),
    });

    return datasets;
  }, [audience?.ages]);

  const ageRangeLabels = (audience?.ages || []).map((ageGroup) => ageGroup.age);

  return (
    <div className={styles.audienceGraphicsContainer}>
      <div
        className={`${styles.item} ${styles.gender} ${styles.firstHalfColumn}`}
      >
        <div className={`${styles.header}`}>
          <span className={styles.title}>
            {translate(`${TRANSLATION_PREFIX}.audience.item-header-1`)}
          </span>
        </div>
        <div className={styles.body}>
          <DonutChart
            datasets={[
              {
                data: [maleGenderPercentage, femaleGenderPercentage],
                backgroundColor: [MALE_COLOR, FEMALE_COLOR],
                borderWidth: 0,
              },
            ]}
            labels={[
              `${maleGenderPercentage}% Men`,
              `${femaleGenderPercentage}% Women`,
            ]}
            displayLegend
            displayTooltip
          />
        </div>
      </div>
      <div
        className={`${styles.item} ${styles.ageRange} ${
          !identity.ldaComplianceStanding ? styles.firstHalfColumn : ''
        }`}
      >
        <div className={styles.header}>
          <span className={styles.title}>
            {translate(`${TRANSLATION_PREFIX}.audience.item-header-2`)}
          </span>
        </div>
        <div className={styles.body}>
          <BarChart
            datasets={ageRangeDatasets}
            labels={ageRangeLabels}
            displayLegend={false}
          />
        </div>
      </div>
      <div
        className={`${styles.item} ${styles.leftColumn} ${styles.locationsList}`}
      >
        <div className={styles.header}>
          <span className={styles.title}>
            {translate(`${TRANSLATION_PREFIX}.locations.header-3`)}
          </span>
        </div>
        <div className={`${styles.body} ${styles.locationsList}`}>
          <LocationsList
            locations={tiktokAccount.states}
            accessorKey="state"
            getLocationName={(state: string) => getStateFullName(state)}
          />
        </div>
      </div>
      <div className={`${styles.item} ${styles.locationsList}`}>
        <div className={styles.header}>
          <span className={styles.title}>
            {translate(`${TRANSLATION_PREFIX}.locations.header-2`)}
          </span>
        </div>
        <div className={`${styles.body} ${styles.locationsList}`}>
          <LocationsList
            locations={tiktokAccount.countries.slice(0, 7)}
            accessorKey="country"
            getLocationName={(country: string) => getCountryFullName(country)}
          />
        </div>
      </div>
    </div>
  );
}

export default AudienceGraphics;
