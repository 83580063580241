import React from 'react';
import styles from './TabWrapper.module.scss';

interface TabContainerProps {
  className?: string;
  children: React.ReactNode;
}

function TabWrapper(props: TabContainerProps) {
  const {className, children} = props;
  return (
    <div className={`${styles.tabWrapperContainer} ${className}`}>
      {children}
    </div>
  );
}

export default TabWrapper;
