import React from 'react';
import {v4 as uuid} from 'uuid';
import AnalyticsService, {
  eventNames,
} from '@/services/analytics/AnalyticsService';
import {SOURCE_NAMES} from '@/lib/analytics/analyticsConstants';
import translate from '@/utils/translate';
import {CREATOR_ACTION_KEYS} from '@/views/creators/components/creator-action/CreatorAction';
import {ActionItem} from '@/views/creators/components/creators-actions-bar/ActionsBar';
import getCreatorIdsForAnalytics from '@/views/creators/utils/getCreatorIdsForAnalytics';
import useSubscriptionQuery from '@/hooks/queries/use-subscription-query';
import useBrandId from '@/hooks/use-brand-id';
import useNavigation from '@/hooks/use-navigation';
import useListCreatorsStore, {
  listCreatorsActions,
} from '@/stores/list-creators/listCreatorsStore';

const TRANSLATION_PREFIX = 'views.creators';

function useActionItems() {
  const {navigate} = useNavigation();
  const selectedCreators = useListCreatorsStore(
    (state) => state.selectedCreators
  );

  const anyDeactivated = [...selectedCreators.values()].some(
    (membership) => membership.status === 'deactivated'
  );

  const anyPending = [...selectedCreators.values()].some(
    (membership) => membership.pending === true
  );

  const conversationIds = [...selectedCreators.values()]
    .filter(
      (membership) =>
        membership?.profile?.currentBrandCollaborationConversation !== undefined
    )
    .map(({profile}) => profile.currentBrandCollaborationConversation.id);

  const brandId = useBrandId();
  const {subscription} = useSubscriptionQuery({brandId});
  const screenPresentationId = uuid();

  return React.useMemo<ActionItem[]>(() => {
    const actions: ActionItem[] = [
      {
        id: CREATOR_ACTION_KEYS.ADD_TO_CAMPAIGN,
        icon: 'Navigation-Megaphone',
        tooltip: translate(
          `${TRANSLATION_PREFIX}.components.creator-action.add-to-campaign.tooltip`
        ),
        onClick: () => {
          listCreatorsActions.setActiveCreatorAction('add-to-campaign');

          AnalyticsService.dispatchEvent(
            eventNames.MY_CREATORS_ACTION_FLOW_STARTED,
            {
              screen_presentation_id: screenPresentationId,
              action_name: CREATOR_ACTION_KEYS.ADD_TO_CAMPAIGN,
              creator_ids: getCreatorIdsForAnalytics([
                ...selectedCreators.values(),
              ]),
              flow_name: translate(
                `${TRANSLATION_PREFIX}.components.creator-action.add-to-campaign.tooltip`
              ),
              campaign_id: '',
              source: SOURCE_NAMES.MY_CREATORS,
            }
          );
        },
        disabled: anyDeactivated,
      },

      {
        id: CREATOR_ACTION_KEYS.MESSAGE_CREATOR,
        icon: 'Navigation-Messages',
        tooltip:
          selectedCreators.size > 1
            ? translate(
                `${TRANSLATION_PREFIX}.components.creator-action.message-creator.bulk-tooltip`
              )
            : translate(
                `${TRANSLATION_PREFIX}.components.creator-action.message-creator.tooltip`
              ),
        onClick: () => {
          if (conversationIds.length > 0) {
            navigate(`/collaborations/${conversationIds[0]}`);
          } else {
            listCreatorsActions.setActiveCreatorAction('message-creator');
          }

          AnalyticsService.dispatchEvent(
            eventNames.MY_CREATORS_ACTION_FLOW_STARTED,
            {
              screen_presentation_id: screenPresentationId,
              action_name: CREATOR_ACTION_KEYS.MESSAGE_CREATOR,
              creator_ids: getCreatorIdsForAnalytics([
                ...selectedCreators.values(),
              ]),
              flow_name: translate(
                `${TRANSLATION_PREFIX}.components.creator-action.message-creator.tooltip`
              ),
              campaign_id: '',
              source: SOURCE_NAMES.MY_CREATORS,
            }
          );
        },
        disabled: selectedCreators.size !== 1 || anyDeactivated || anyPending,
      },
      {
        id: CREATOR_ACTION_KEYS.REMOVE_FROM_MY_CREATORS,
        icon: 'Actions-Remove',
        tooltip: translate(
          `${TRANSLATION_PREFIX}.components.creator-action.remove-creator.tooltip`
        ),
        onClick: () => {
          listCreatorsActions.setActiveCreatorAction('remove-from-my-creators');

          AnalyticsService.dispatchEvent(
            eventNames.MY_CREATORS_ACTION_FLOW_STARTED,
            {
              screen_presentation_id: screenPresentationId,
              action_name: CREATOR_ACTION_KEYS.REMOVE_FROM_MY_CREATORS,
              creator_ids: getCreatorIdsForAnalytics([
                ...selectedCreators.values(),
              ]),
              flow_name: translate(
                `${TRANSLATION_PREFIX}.components.creator-action.remove-creator.tooltip`
              ),
              campaign_id: '',
              source: SOURCE_NAMES.MY_CREATORS,
            }
          );
        },
      },
    ];

    const visibleActions = actions.filter((action) => {
      if (subscription?.isCampaign) {
        return action.id !== CREATOR_ACTION_KEYS.MESSAGE_CREATOR;
      }

      return true;
    });

    return visibleActions;
  }, [
    selectedCreators,
    anyDeactivated,
    conversationIds,
    navigate,
    subscription,
  ]);
}

export default useActionItems;
