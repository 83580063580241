import React from 'react';
import {Body} from '@lightricks/react-design-system';
import calculateAge from '@/utils/calculateAge';
import {ProfileProps} from '@/types/creatorProfile';

function CreatorProfileInfo({profile}: {profile: ProfileProps}) {
  const age = calculateAge(profile.birthDate);
  const {gender, location} = profile;

  return (
    <Body size="lg">{[age, gender, location].filter(Boolean).join('. ')}</Body>
  );
}

export default CreatorProfileInfo;
