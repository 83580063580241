import React from 'react';
import {Button, Label} from '@lightricks/react-design-system';
import styles from './CreatorCardCta.module.scss';

function CreatorCardCta({
  buttonText,
  onClick,
}: {
  buttonText: string;
  onClick?: (event: React.MouseEvent) => void;
}) {
  return (
    <Button
      className={styles.ctaButton}
      appearance="neutral"
      mode="tinted"
      size="medium"
      onClick={onClick}
    >
      <Label size="md">{buttonText}</Label>
    </Button>
  );
}

export default CreatorCardCta;
