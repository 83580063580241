import React, {useMemo} from 'react';
import {
  designSystemToken,
  Label,
  Tooltip,
} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import {CreatorProfileAbilities, ProfileProps} from '@/types/creatorProfile';
import {TABS} from '@/components/creator-profile/constants';

const TRANSLATION_PREFIX = 'components.creator-profile';
const lockedIcon = '/assets/svg/icon-lock-locked.svg';

interface UseCreatorProfileTabsParams {
  profile: ProfileProps;
  creatorProfileAbilities: CreatorProfileAbilities;
}

interface UseCreatorProfileTabs {
  id: string;
  localeLabelKey: string;
  lockedLabelKey: string;
  publicLocked: boolean;
  value: string;
  label: React.JSX.Element | string;
  startAdornment: React.JSX.Element | null;
  locked: boolean;
}

const AMOUNT_INDICATOR_SIZE = 16;

function AmountIndicator({amount}: {amount: number}) {
  return (
    <Label
      className="Mui-endAdornment"
      size="sm"
      sx={{
        width: AMOUNT_INDICATOR_SIZE,
        height: AMOUNT_INDICATOR_SIZE,
        fontSize: 9,
        alignItems: 'center',
        textAlign: 'center',
        borderRadius: '100%',
        background: designSystemToken('semantic.fg.primary'),
      }}
    >
      {amount}
    </Label>
  );
}

function LockedIndicator() {
  return <img src={lockedIcon} alt="locked" />;
}

/**
 * returns the tabs that should be displayed in the creator profile depending on various permissions
 * @param profile
 * @param permissions
 */
function useCreatorProfileTabs({
  profile,
  creatorProfileAbilities,
}: UseCreatorProfileTabsParams): UseCreatorProfileTabs[] {
  return useMemo(() => {
    const tabs = [];
    if (creatorProfileAbilities.showOverviewTab) {
      tabs.push({...TABS.OVERVIEW});
    }
    if (creatorProfileAbilities.displayPublicDataOnly) {
      tabs.push({...TABS.AUDIENCE});
    }
    if (creatorProfileAbilities.showAnalyticsAndAudienceTab) {
      tabs.push({...TABS.ANALYTICS_AND_AUDIENCE});
    }
    if (creatorProfileAbilities.showPostsTab) {
      tabs.push({...TABS.POSTS});
    }
    if (creatorProfileAbilities.showPastCampaignsTab) {
      const pastCampaignsCount = profile?.profilePreviousCampaigns?.length;

      const endAdornment = pastCampaignsCount ? (
        <AmountIndicator amount={pastCampaignsCount} />
      ) : null;

      tabs.push({...TABS.PAST_CAMPAIGNS, endAdornment});
    }
    if (creatorProfileAbilities.showNotesTab) {
      tabs.push({...TABS.NOTES});
    }

    return tabs.map((tab) => {
      const isLocked =
        tab.publicLocked && creatorProfileAbilities.displayPublicDataOnly;

      const label = translate(`${TRANSLATION_PREFIX}.${tab.localeLabelKey}`);

      const wrappedLabel = isLocked ? (
        <Tooltip
          title={
            <Label
              size="md"
              color={designSystemToken('semantic.fg.inverse')}
              sx={{textAlign: 'center', maxWidth: 200}}
            >
              {translate(`${TRANSLATION_PREFIX}.${tab.lockedLabelKey}`)}
            </Label>
          }
          type="dark"
          placement="top"
          arrow
        >
          <div>{label}</div>
        </Tooltip>
      ) : (
        label
      );

      return {
        ...tab,
        value: tab.id,
        label: wrappedLabel,
        startAdornment: isLocked ? <LockedIndicator /> : null,
        locked: isLocked,
      };
    });
  }, [creatorProfileAbilities, profile?.profilePreviousCampaigns]);
}

export default useCreatorProfileTabs;
