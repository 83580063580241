import React from 'react';
import {ViewType} from '@/types/models/search-creators/searchCreatorsStore';
import ActionDrawerButtons from '@/views/creators/components/action-drawer-buttons';
import getAnalyticsSearchScreenName from '@/views/creators/search/utils/getAnalyticsSearchScreenName';
import TotalResults from '@/components/total-results';
import ViewTypeSelector from '@/components/view-type-selector';
import useTrackSearchButtonEvent from '@/hooks/analytics/use-track-search-button-event';
import {
  searchCreatorsActions,
  VIEW_TYPE_GRID,
  VIEW_TYPE_TABLE,
} from '@/stores/search-creators/searchCreatorsStore';
import styles from './SearchControls.module.scss';

type SearchControlsProps = {
  totalResults: number;
  searchQuery?: string;
  viewType: ViewType;
  onActionDrawerButtonClick?: (label: string) => void;
};

const gridIcon = '/assets/svg/grid.svg';
const listIcon = '/assets/svg/list.svg';

const VIEW_TYPES = [
  {
    id: 'grid',
    icon: gridIcon,
  },
  {
    id: 'table',
    icon: listIcon,
  },
];

const analyticsButtonName = {
  [VIEW_TYPE_TABLE]: 'cards-view',
  [VIEW_TYPE_GRID]: 'list-view',
};

function SearchControls({
  totalResults,
  searchQuery,
  viewType,
  onActionDrawerButtonClick,
}: SearchControlsProps) {
  const trackSearchButtonEvent = useTrackSearchButtonEvent();
  return (
    <div className={styles.searchControls}>
      <div className={styles.left}>
        <ActionDrawerButtons
          onActionDrawerButtonClick={onActionDrawerButtonClick}
        />
      </div>
      <div className={styles.right}>
        <TotalResults amount={totalResults} searchTerm={searchQuery} />
        <ViewTypeSelector
          viewTypes={VIEW_TYPES}
          selectedViewTypeId={viewType}
          onChange={(viewTypeId) => {
            trackSearchButtonEvent.pressed({
              button_name: analyticsButtonName[viewType],
              screen_name: getAnalyticsSearchScreenName(viewType),
            });
            searchCreatorsActions.changeViewType(viewTypeId as ViewType);
          }}
        />
      </div>
    </div>
  );
}

export default SearchControls;
