import React from 'react';
import {ProfileProps} from '@/types/creatorProfile';
import {Identity} from '@/types/identity';
import Link from '@/components/link';
import SocialNetworkIcon from '@/components/social-network-icon';
import useFilteredTiktokSocialNetworks from '@/hooks/use-filtered-tiktok-social-networks';
import useSortedSocialNetworks from '@/hooks/use-sorted-social-networks';
import styles from './CreatorProfileSocialNetworks.module.scss';

const SOCIAL_NETWORK_ICON_SIZE = 24;

interface CreatorProfileSocialNetworksProps {
  profile: ProfileProps;
  onSocialNetworkClick?: (identity: Identity) => void;
}

function CreatorProfileSocialNetworks({
  profile,
  onSocialNetworkClick,
}: CreatorProfileSocialNetworksProps) {
  const socialNetworks = profile.identities || [];

  const sortedSocialNetworks = useSortedSocialNetworks(
    socialNetworks
  ) as Identity[];

  const filteredSocialNetworks =
    useFilteredTiktokSocialNetworks(sortedSocialNetworks);

  return (
    <div className={styles.creatorProfileSocialNetworks}>
      {filteredSocialNetworks.map((network: Identity) => {
        return (
          <Link
            to={network.profilePageUrl}
            key={network.provider}
            target="_blank"
            rel="noopener noreferrer"
            keepBrandIdIfPresent={false}
            onClick={() => onSocialNetworkClick?.(network)}
          >
            <div className={styles.iconContainer}>
              <SocialNetworkIcon
                name={network.provider}
                width={SOCIAL_NETWORK_ICON_SIZE}
                height={SOCIAL_NETWORK_ICON_SIZE}
                withHoverMode
              />
            </div>
          </Link>
        );
      })}
    </div>
  );
}

export default CreatorProfileSocialNetworks;
