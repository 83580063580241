import React, {useEffect, useState} from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import {Button} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import Conditional from '@/components/conditional';
import useUpdateBrandProfileNote from '@/hooks/mutations/use-update-brand-profile-note';
import useBrandProfileNoteQuery from '@/hooks/queries/use-brand-profile-note-query';
import useBrandId from '@/hooks/use-brand-id';
import {CreatorProfileTabContentProps} from '../../CreatorProfileTabContentProps';
import TabWrapper from '../tab-wrapper';
import styles from './NotesTab.module.scss';

const TRANSLATION_PREFIX = 'components.creator-profile.tabs.notes';

function NotesTab(props: CreatorProfileTabContentProps) {
  const brandId = useBrandId();
  const {profile, creatorProfileAbilities, trackButtonPressed} = props;
  const [text, setText] = useState('');
  const [showButtons, setShowButtons] = useState(false);

  const {data: note, isLoading} = useBrandProfileNoteQuery({
    brandId,
    profileId: profile.id,
    enabled: !creatorProfileAbilities.displayPublicDataOnly,
  });

  const updateNote = useUpdateBrandProfileNote({
    brandId,
    profileId: profile.id,
  });

  useEffect(() => {
    if (note) {
      setText(note.bodyRaw);
    }
  }, [note]);

  const onCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
    trackButtonPressed((event.target as HTMLButtonElement).innerText);
    setText(note?.bodyRaw || '');
    setShowButtons(false);
  };

  const onSave = async (event: React.MouseEvent<HTMLButtonElement>) => {
    trackButtonPressed((event.target as HTMLButtonElement).innerText);
    if (note?.bodyRaw === text || !note) {
      setShowButtons(false);
      return;
    }

    await updateNote.mutateAsync({...note, bodyRaw: text});
    setShowButtons(false);
  };

  return (
    <TabWrapper className={styles.notesTabContainer}>
      <div>
        <TextareaAutosize
          className={`${styles.textarea} ${showButtons ? styles.active : ''}`}
          placeholder={translate(`${TRANSLATION_PREFIX}.note-placeholder`)}
          value={text}
          disabled={isLoading}
          onChange={({target: {value}}) => setText(value)}
          minRows={10}
          onFocus={() => setShowButtons(true)}
        />
      </div>
      <Conditional condition={showButtons}>
        <div className={styles.buttonsContainer}>
          <Button
            appearance="neutral"
            mode="plain"
            size="medium"
            onClick={onCancel}
          >
            {translate(`${TRANSLATION_PREFIX}.cancel`)}
          </Button>
          <Button
            appearance="neutral"
            mode="tinted"
            size="medium"
            onClick={onSave}
          >
            {translate(`${TRANSLATION_PREFIX}.save`)}
          </Button>
        </div>
      </Conditional>
    </TabWrapper>
  );
}

export default NotesTab;
