import React, {useEffect, useMemo, useState} from 'react';
import {useLocation, useSearchParams} from 'react-router-dom';
import {Modal} from '@lightricks/react-design-system';
import {raiseFlashMessageError} from '@/utils/raiseFlashMessage';
import {ROUTES} from '@/config/routesConstants';
import {ProfileProps} from '@/types/creatorProfile';
import {DashboardUser} from '@/types/dashboardUser';
import CreatorProfile from '@/components/creator-profile';
import useCreatorProfileQuery from '@/hooks/queries/use-creator-profile-query';
import useDashboardUserQuery from '@/hooks/queries/use-dashboard-user-query';
import useBrandId from '@/hooks/use-brand-id';
import useNavigation from '@/hooks/use-navigation';
import useToken from '@/hooks/use-token';
import styles from './CreatorProfileModal.module.scss';

function CreatorProfileModal() {
  const {isAuthenticated} = useToken(false, true);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigation = useNavigation();
  const [navigationChecked, setNavigationChecked] = useState(false);
  const creatorProfileIdRedirect = searchParams.get('creatorProfileIdRedirect');
  const creatorProfileId = searchParams.get('creatorProfileId');
  const location = useLocation();

  const profileId = creatorProfileIdRedirect || creatorProfileId;

  const isOpen = !!profileId && isAuthenticated;

  useEffect(() => {
    // opened site with creatorProfileIdRedirect in query params =>
    //  navigate to the creator profile page instead of opening as a modal
    if (isAuthenticated && creatorProfileIdRedirect) {
      navigation.navigate(
        `${ROUTES.CREATOR_PROFILE}/${creatorProfileIdRedirect}`
      );
    }
    setNavigationChecked(true);
  }, []);

  useEffect(() => {
    if (!isAuthenticated && profileId) {
      handleClose();
    }
  }, [profileId, isAuthenticated]);

  /**
   * Abilities that the modal should have based on the current location
   */
  const modalAbilities = useMemo(() => {
    if (!profileId) {
      return {};
    }

    const lowerCasePathname = location.pathname.toLowerCase();

    const isSearch = lowerCasePathname.includes('search');
    const isProposal = lowerCasePathname.includes('proposals');
    const isInvite = lowerCasePathname.includes('candidates');

    return {
      componentAllowsCollaboration: isSearch,
      componentAllowsInviteToCampaign: isSearch,
      componentAllowsAddMembership: isSearch || isProposal || isInvite,
    };
  }, [location.pathname, profileId]);

  const {data: dashboardUser, isLoading: isLoadingDashboardUser} =
    useDashboardUserQuery({});

  const brandId = useBrandId();

  const queriesEnabled = !!profileId && !!brandId && isAuthenticated;

  const handleClose = () => {
    searchParams.delete('creatorProfileIdRedirect');
    searchParams.delete('creatorProfileId');
    searchParams.delete('activeTabId');
    searchParams.delete('activeSocialNetwork');
    setSearchParams(searchParams);
  };

  const {
    profile,
    isLoading: isLoadingProfile,
    isError: isErrorProfile,
  } = useCreatorProfileQuery({
    profileId,
    brandId,
    enabled: queriesEnabled,
  });

  useEffect(() => {
    if (isErrorProfile) {
      raiseFlashMessageError();
      handleClose();
    }
  }, [isErrorProfile]);

  const renderModalContent = () => {
    return (
      <div className={`${styles.modalContent}`}>
        <CreatorProfile
          testID="creator-profile"
          containerClassName={styles.creatorProfileModalContainer}
          isLoading={isLoadingProfile || isLoadingDashboardUser}
          profile={profile as ProfileProps}
          dashboardUser={dashboardUser as DashboardUser}
          displayPublicDataOnly={false}
          componentAllowsAddMembership
          componentAllowsCollaboration
          componentAllowsInviteToCampaign
          canViewAudience
          canViewSocialHealth
          canViewPortfolio
          canViewPosts
          canViewProfileNotes
          isModal
          {...modalAbilities}
        />
      </div>
    );
  };

  if (!navigationChecked) {
    return null;
  }

  return (
    <Modal
      open={isOpen}
      showCloseButton={false}
      handleClose={handleClose}
      className={styles.modal}
    >
      {renderModalContent()}
    </Modal>
  );
}

export default CreatorProfileModal;
