import React from 'react';
import {Body, designSystemToken} from '@lightricks/react-design-system';
import formattedTimestamp from '@/utils/formattedTimestamp';
import translate from '@/utils/translate';
import {MONTH_SHORT_DAY_YEAR_FORMAT} from '@/config/timeFormats';
import {ProfileProps} from '@/types/creatorProfile';
import Conditional from '@/components/conditional';
import CreatorProfileRating from '@/components/creator-profile/components/creator-profile-rating';
import styles from './CreatorProfileRatingAndLastActiveAt.module.scss';

const TRANSLATION_PREFIX = 'components.creator-profile.header';

function CreatorProfileRatingAndLastActiveAt({
  profile,
  showProfileRating,
  showCompletedGigsCount,
  showLastActiveAt,
}: {
  profile: ProfileProps;
  showProfileRating: boolean;
  showCompletedGigsCount: boolean;
  showLastActiveAt: boolean;
}) {
  return (
    <div className={styles.creatorProfileRatingAndLastActiveAt}>
      <div className={styles.rating}>
        <Conditional condition={showProfileRating}>
          <CreatorProfileRating profile={profile} />
        </Conditional>
        <Conditional condition={showCompletedGigsCount}>
          <Body size="md">
            {translate(`${TRANSLATION_PREFIX}.completed-gigs`, {
              amount: profile.completedPaidGigsCount,
            })}
          </Body>
        </Conditional>
      </div>
      <Conditional condition={showLastActiveAt}>
        <Body
          size="sm"
          color={designSystemToken('semantic.fg.inverse-secondary')}
        >
          {translate(`${TRANSLATION_PREFIX}.last-active`, {
            lastActiveDate: formattedTimestamp(
              profile.lastActiveAt,
              MONTH_SHORT_DAY_YEAR_FORMAT
            ),
          })}
        </Body>
      </Conditional>
    </div>
  );
}

export default CreatorProfileRatingAndLastActiveAt;
