import {partition} from 'lodash';
import React from 'react';
import {Box} from '@mui/material';
import {Headline} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import type CreatorGroupLabel from '@/types/creatorGroupLabel';
import useAgencyQuery from '@/hooks/queries/use-agency-query';
import useBrandId from '@/hooks/use-brand-id';
import styles from './ListDrawer.module.scss';
import {List, NewListButton, ListDrawerAccordion} from './components';
import useListProps from './hooks/useListProps';

const TRANSLATION_PREFIX = 'components.list-drawer';

export type ListDrawerProps = {
  testID?: string;
  labels: CreatorGroupLabel[];
};

function ListDrawer(props: ListDrawerProps) {
  const {testID = 'list-drawer', labels} = props;
  const brandId = useBrandId();
  const {currentBrand} = useAgencyQuery({brandId});
  const listProps = useListProps();

  const isSharedList = ({organizationLevel}: CreatorGroupLabel) =>
    organizationLevel === true;

  const [sharedLists, brandLists] = partition(labels, isSharedList);

  return (
    <div className={styles.container} data-testid={testID}>
      <Box
        className={styles.stickyHeader}
        sx={{marginBottom: sharedLists.length === 0 ? '12px' : null}}
      >
        <div className={styles.heading}>
          <Headline size="xs">
            {translate(`${TRANSLATION_PREFIX}.heading`)}
          </Headline>
        </div>
        <NewListButton />
      </Box>
      {sharedLists.length === 0 ? (
        brandLists?.map((group) => (
          <List key={group.id} {...listProps(group)} />
        ))
      ) : (
        <>
          <ListDrawerAccordion
            label={translate(`${TRANSLATION_PREFIX}.shared-lists`)}
          >
            {sharedLists?.map((group) => (
              <List key={group.id} {...listProps(group)} />
            ))}
          </ListDrawerAccordion>
          <div className={styles.divider} />
          <ListDrawerAccordion
            label={translate(`${TRANSLATION_PREFIX}.brand-lists`, {
              brandName:
                currentBrand?.name ??
                translate(
                  `${TRANSLATION_PREFIX}.brand-lists-brand-name-fallback`
                ),
            })}
            preIconName="Actions-Lock-Line"
          >
            {brandLists?.map((group) => (
              <List key={group.id} {...listProps(group)} />
            ))}
          </ListDrawerAccordion>
        </>
      )}
    </div>
  );
}

export default ListDrawer;
